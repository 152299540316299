<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
    <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.179 16.8294C15.9949 17.8275 14.459 18.43 12.7807 18.43C9.03582 18.43 6 15.4303 6 11.73C6 8.02966 9.03582 5.02997 12.7807 5.02997C14.459 5.02997 15.9949 5.63247 17.179 6.63051C18.363 5.63247 19.8989 5.02997 21.5773 5.02997C25.3221 5.02997 28.358 8.02966 28.358 11.73C28.358 15.4303 25.3221 18.43 21.5773 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8294Z"
      fill="#ED0006"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.179 16.8294C18.6369 15.6005 19.5614 13.7719 19.5614 11.73C19.5614 9.68801 18.6369 7.85941 17.179 6.63051C18.363 5.63247 19.8989 5.02997 21.5772 5.02997C25.3221 5.02997 28.3579 8.02966 28.3579 11.73C28.3579 15.4303 25.3221 18.43 21.5772 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8294Z"
      fill="#F9A000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.179 16.8294C18.6369 15.6005 19.5614 13.7719 19.5614 11.73C19.5614 9.68805 18.6369 7.85946 17.179 6.63055C15.7211 7.85946 14.7966 9.68805 14.7966 11.73C14.7966 13.7719 15.7211 15.6005 17.179 16.8294Z"
      fill="#FF5E00"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '24',
    },
    width: {
      type: String,
      default: '34',
    },
    color: {
      type: String,
      default: 'menu',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
